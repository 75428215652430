import React from "react"
import WhatLayout from "../../components/WhatLayout";

import bild01 from "../../images/what/proud/cernunnos-daughters.png";
import bild02 from "../../images/what/proud/proud-rose.png";
import bild03 from "../../images/what/proud/regenbogen-traumfaenger.png";
import bild04 from "../../images/what/proud/through-the-rainbow.png";
import bild05 from "../../images/what/proud/break-and-fences.png";
import bild06 from "../../images/what/proud/comingout.png";
import bild07 from "../../images/what/proud/deckblatt-sternzeichenkalender.png";
import bild08 from "../../images/what/proud/dont-drop-the-mic.png";
import bild09 from "../../images/what/proud/fische-2021.png";
import bild10 from "../../images/what/proud/fly-high.png";
import bild11 from "../../images/what/proud/jungfrau-2020.png";
import bild12 from "../../images/what/proud/krebs-2020.png";
import bild13 from "../../images/what/proud/loewe-2020.png";
import bild14 from "../../images/what/proud/pride-sekt.png";
import bild15 from "../../images/what/proud/waage-2020.png";
import bild16 from "../../images/what/proud/widder-2020.png";
import bild17 from "../../images/what/proud/zwillinge-2020.png";

const Page = () => {

  return (
    <WhatLayout
      header={"Proud"}
      images={[
        bild01,
        bild02,
        bild03,
        bild04,
        bild05,
        bild06,
        bild07,
        bild08,
        bild09,
        bild10,
        bild11,
        bild12,
        bild13,
        bild14,
        bild15,
        bild16,
        bild17
      ]}
    />
  );

};

export default Page;
